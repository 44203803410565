import { Link } from "react-router-dom";
import Unauthorized from "@components/layout/Header/components/Unauthorized";
import Authorized from "@components/layout/Header/components/Authorized";

import "./header.sass";
import { useSelector } from "react-redux";
import { LogoIcon } from "@components/library";

export const Header = ({ setSidebar }) => {
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <header className="header header--fixed">
      <div className="header__wrapper">
          <div className="header__left">
            <>
              <Link className="header__logo" to="/">
                <LogoIcon />
              </Link>
            </>
          </div>

        {isAuth ? <Authorized setSidebar={setSidebar} /> : <Unauthorized />}
      </div>
    </header>
  );
};

import React from "react";

export const SettingsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.444 1L6.358 1.43671L6.02901 3.03467C5.50301 3.23554 5.026 3.52037 4.595 3.85815L2.987 3.3205L2.55501 3.18658L2.33101 3.57233L1.224 5.4231L1 5.8089L1.328 6.0957L2.57201 7.15399C2.52701 7.43081 2.46901 7.70461 2.46901 7.99542C2.46901 8.28623 2.52701 8.5601 2.57201 8.83691L1.328 9.8952L1 10.182L1.224 10.5677L2.33101 12.4186L2.55501 12.8053L2.987 12.6704L4.595 12.1328C5.026 12.4705 5.50301 12.7553 6.02901 12.9562L6.358 14.5541L6.444 14.9909H9.55501L9.642 14.5541L9.97 12.9562C10.496 12.7553 10.973 12.4705 11.404 12.1328L13.012 12.6704L13.444 12.8053L13.669 12.4186L14.775 10.5677L15 10.182L14.671 9.8952L13.427 8.83691C13.473 8.5601 13.53 8.28623 13.53 7.99542C13.53 7.70461 13.473 7.43081 13.427 7.15399L14.671 6.0957L15 5.8089L14.775 5.4231L13.669 3.57233L13.444 3.18658L13.012 3.3205L11.404 3.85815C10.973 3.52037 10.496 3.23554 9.97 3.03467L9.642 1.43671L9.55501 1H6.444Z"
        stroke="#707070"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99963 7.99542C9.99963 9.0987 9.10363 9.99414 7.99963 9.99414C6.89563 9.99414 5.99963 9.0987 5.99963 7.99542C5.99963 6.89214 6.89563 5.99677 7.99963 5.99677C9.10363 5.99677 9.99963 6.89214 9.99963 7.99542Z"
        stroke="#707070"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import React, { useState } from "react";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Skeleton,
  Stack,
} from "@mui/material";
import { Button, Input } from "../../../library";
import MyLabel from "../../../library/UI/Input/MyLabel";
import { Controller, useForm } from "react-hook-form";
import { WarningIcon } from "../../../library/Icons/WarningIcon";
import axios from "@helpers/axios/private.axios";

const NewLink = ({ offerTypes, loading, setCampaigns, setCampaignNames }) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      campaign_name: "",
      offer_type: "",
      promo_code: "",
    },
  });

  const onSubmit = async ({ campaign_name, offer_type, promo_code }) => {
    try {
      setLoadingForm(true);
      const resp = await axios.post("/affiliate_links/store", {
        campaign_name,
        offer: offer_type,
        promo_code,
      });
      setLoadingForm(false);
      reset();
      setCampaigns((campaigns) => [resp.data.data, ...campaigns]);
      setCampaignNames((names) => [resp.data.data.campaign_name, ...names]);
    } catch (error) {
      setLoadingForm(false);
      const data = error?.response?.data?.errors;
      if (data) {
        Object.keys(data).forEach((error) => {
          let nameError = error;

          setError(nameError, {
            type: "custom",
            message: data[error][0],
          });
        });
      }
    }
  };

  console.log("errors", errors);

  return (
    <div className="main-info">
      <h1 className="main-info__title">Create a new affiliate link</h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columnSpacing="24px" rowSpacing="15px">
          <Grid item md={6} xs={12}>
            <Input
              className="profile__inp-wrap"
              data={{
                label: "Campaign name",
                type: "text",
                placeholder: "Enter campaign name",
                errors,
                settings: {
                  ...register("campaign_name", {
                    required: true,
                  }),
                },
                name: "campaign_name",
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <MyLabel label="Offer type">
              <FormControl fullWidth>
                <Controller
                  name="offer_type"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      labelId="offer_type"
                      id="offer_type"
                      className={`bo-select ${
                        errors["offer_type"] ? "error" : ""
                      }`}
                      {...field}
                      variant="standard"
                      MenuProps={{
                        className: "bo-select-content",
                      }}
                    >
                      {loading ? (
                        <Stack spacing="6px">
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width="100%"
                            height={60}
                            className="bo-skeleton"
                          />
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width="100%"
                            height={60}
                            className="bo-skeleton"
                          />
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width="100%"
                            height={60}
                            className="bo-skeleton"
                          />
                        </Stack>
                      ) : (
                        offerTypes.map((data) => (
                          <MenuItem key={data.value} value={data.value}>
                            {data.name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  )}
                />
              </FormControl>

              {errors["offer_type"] && (
                <p className="bo-input__error">
                  {errors["offer_type"].message}
                </p>
              )}
            </MyLabel>
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              className="profile__inp-wrap"
              data={{
                label: "Promo code",
                type: "text",
                placeholder: "Enter promo code",
                errors,
                settings: {
                  ...register("promo_code", {}),
                },
                name: "promo_code",
              }}
            />
          </Grid>
          {/*<Grid item md={6} xs={12}>*/}
          {/*<MyLabel label="Promo code">*/}
          {/*  <FormControl fullWidth>*/}
          {/*    <Controller*/}
          {/*      name="promo_code"*/}
          {/*      control={control}*/}
          {/*      rules={{ required: true }}*/}
          {/*      render={({ field }) => (*/}
          {/*        <Select*/}
          {/*          labelId="demo-simple-select-label"*/}
          {/*          id="demo-simple-select"*/}
          {/*          className="bo-select"*/}
          {/*          {...field}*/}
          {/*          variant="standard"*/}
          {/*          MenuProps={{*/}
          {/*            className: "bo-select-content",*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          /!*{loading ? (*!/*/}
          {/*          /!*  <Stack spacing="6px">*!/*/}
          {/*          /!*    <Skeleton*!/*/}
          {/*          /!*      animation="wave"*!/*/}
          {/*          /!*      variant="rectangular"*!/*/}
          {/*          /!*      width="100%"*!/*/}
          {/*          /!*      height={60}*!/*/}
          {/*          /!*      className="bo-skeleton"*!/*/}
          {/*          /!*    />*!/*/}
          {/*          /!*    <Skeleton*!/*/}
          {/*          /!*      animation="wave"*!/*/}
          {/*          /!*      variant="rectangular"*!/*/}
          {/*          /!*      width="100%"*!/*/}
          {/*          /!*      height={60}*!/*/}
          {/*          /!*      className="bo-skeleton"*!/*/}
          {/*          /!*    />*!/*/}
          {/*          /!*    <Skeleton*!/*/}
          {/*          /!*      animation="wave"*!/*/}
          {/*          /!*      variant="rectangular"*!/*/}
          {/*          /!*      width="100%"*!/*/}
          {/*          /!*      height={60}*!/*/}
          {/*          /!*      className="bo-skeleton"*!/*/}
          {/*          /!*    />*!/*/}
          {/*          /!*  </Stack>*!/*/}
          {/*          /!*) : (*!/*/}
          {/*          /!*country.map((val, index) => (*!/*/}
          {/*          <MenuItem key="Volume Sharing" value="Volume Sharing">*/}
          {/*            Do not use promo code*/}
          {/*          </MenuItem>*/}
          {/*          /!*))*!/*/}
          {/*          /!*)}*!/*/}
          {/*        </Select>*/}
          {/*      )}*/}
          {/*    />*/}
          {/*  </FormControl>*/}
          {/*</MyLabel>*/}
          {/*</Grid>*/}
          <Grid item md={6} xs={12}>
            <div className="warning">
              <span className="warning__icon">
                <WarningIcon />
              </span>
              <p className="warning__text">
                Volume sharing offer — You earn commission on both net trading
                tumover and Company's revenue based on the total trading
                activity of your referrals
              </p>
            </div>
          </Grid>
        </Grid>

        <Button
          isLoading={loadingForm}
          className="affiliateLinks__create-btn"
          color="orange"
          size="middle"
          type="submit"
        >
          Create
        </Button>
      </form>
    </div>
  );
};

export default NewLink;

import axios from "axios";
import config from "../../config";
import { AUTH } from "../../constants";
import store from "../../redux/store";

const privateInstance = axios.create({
  baseURL: config.api_url,
});

export const configurateToken = (token) => {
  privateInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeUserSession = () => {
  store.dispatch({ type: AUTH.AUTH_LOGOUT });
};

privateInstance.interceptors.response.use(
  function (response) {
    // refreshToken(response);
    return response;
  },
  function (error) {
    if (error.response === undefined) {
      removeUserSession();
    } else if (error.response.status === 401) {
      removeUserSession();
    }
    return Promise.reject(error);
  }
);

export default privateInstance;

import React from "react";

export const HelpIcon = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00033 13.3332C10.222 13.3332 12.8337 10.7215 12.8337 7.49984C12.8337 4.27818 10.222 1.6665 7.00033 1.6665C3.77866 1.6665 1.16699 4.27818 1.16699 7.49984C1.16699 10.7215 3.77866 13.3332 7.00033 13.3332Z"
        fill="white"
      />
      <path
        d="M5.30273 5.74984C5.43988 5.35998 5.71057 5.03124 6.06687 4.82184C6.42318 4.61244 6.84209 4.53589 7.24942 4.60576C7.65675 4.67563 8.02621 4.8874 8.29236 5.20357C8.55851 5.51974 8.70418 5.9199 8.70357 6.33318C8.70357 7.49984 6.95357 8.08318 6.95357 8.08318"
        stroke="#232323"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 10.4165H7.00583"
        stroke="#232323"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

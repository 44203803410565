import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { DashboardIcon, AffiliateIcon, WalletIcon } from "@components/library";
import { Button } from "@components/library";
import { OfferIcon, ProfileIcon, SettingsIcon, SmsIcon } from "../Icons";
import { useMediaQuery, useTheme } from "@mui/material";
import { removeUserSession } from "../../../helpers/axios/private.axios";
import { useSelector } from "react-redux";

import "./sidebar.sass";
const Sidebar = ({ open, setOpen }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const { full_name = "", email } = useSelector((state) => state.user.user);
  const navigate = useNavigate();

  const logout = () => {
    navigate("/");
    removeUserSession();
  };

  return (
    <>
      <div className={`sidebar ${open ? "active" : ""}`}>
        <div className="sidebar__wrap">
          {isMobile && (
            <>
              <button
                onClick={() => setOpen(false)}
                className="sidebar__close"
                type="button"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
                    fill="white"
                  />
                </svg>
              </button>
              <header className="sidebar__header">
                <h3 className="sidebar__name">dsfsafs</h3>
                {full_name && <h3 className="sidebar__name">{full_name}</h3>}
                {email && <p className="sidebar__email">{email}</p>}
              </header>
            </>
          )}

          <nav className="sidebar__nav nav">
            <ul className="nav__list">
              <li className="nav__item">
                <NavLink to="/" className="nav__link">
                  <DashboardIcon />
                  Dashboard
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink to="/affiliate-links" className="nav__link">
                  <AffiliateIcon />
                  Affiliate links
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink to="/wallet" className="nav__link">
                  <WalletIcon />
                  Wallet
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink to="/offers" className="nav__link">
                  <OfferIcon />
                  Offers
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink to="/profile" className="nav__link">
                  <ProfileIcon />
                  Profile
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink to="/settings" className="nav__link">
                  <SettingsIcon />
                  Security Settings
                </NavLink>
              </li>
            </ul>
          </nav>

          {isMobile && (
            <>
              <div className="sidebar__bottom">
                <Button
                  type="link"
                  className="sidebar__support"
                  to="/auth/login"
                  color="black"
                  size="mini"
                >
                  <SmsIcon />
                  Support
                </Button>
                <button
                  onClick={logout}
                  type="button"
                  className="sidebar__logout"
                >
                  Logout
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        onClick={() => setOpen(false)}
        className={`sidebar__bg ${open ? "active" : ""}`}
      ></div>
    </>
  );
};

export default Sidebar;

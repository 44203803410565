export const CopyIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 8.0625V10.6875C10 12.875 9.125 13.75 6.9375 13.75H4.3125C2.125 13.75 1.25 12.875 1.25 10.6875V8.0625C1.25 5.875 2.125 5 4.3125 5H6.9375C9.125 5 10 5.875 10 8.0625Z"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 4.3125V6.9375C13.75 9.125 12.875 10 10.6875 10H10V8.0625C10 5.875 9.125 5 6.9375 5H5V4.3125C5 2.125 5.875 1.25 8.0625 1.25H10.6875C12.875 1.25 13.75 2.125 13.75 4.3125Z"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

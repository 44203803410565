import React, { useEffect, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import { Status } from "@library";
import axios from "@helpers/axios/private.axios";

const PaymentHistory = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const resp = await axios.get("/transactions/payment");

        setData(resp.data.data);
        setLoading(false);
      } catch (e) {
        console.log(e, data, loading);
      }
    };

    getData();
  }, []);

  return (
    <Box sx={{ overflowX: "auto" }}>
      <Box sx={{ minWidth: 890 }} className="table">
        <div className="table__tr table__tr--head">
          <Box
            sx={{
              flex: "0 0 28%",
            }}
            className="table__td"
          >
            <p className="table__text">#</p>
          </Box>
          <Box
            sx={{
              flex: "0 0 28%",
            }}
            className="table__td"
          >
            <p className="table__text">Date of creation</p>
          </Box>
          <Box
            sx={{
              flex: "0 0 27%",
            }}
            className="table__td"
          >
            <p className="table__text">Amount </p>
          </Box>
          <Box
            sx={{
              flex: "0 0 17%",
            }}
            className="table__td"
          >
            <p className="table__text table__text--end">Status</p>
          </Box>
        </div>

        {loading ? (
          <>
            <div className="table__tr">
              <Skeleton
                className="table__skeleton"
                variant="rounded"
                width="100%"
              />
            </div>
            <div className="table__tr">
              <Skeleton
                className="table__skeleton"
                variant="rounded"
                width="100%"
              />
            </div>
            <div className="table__tr">
              <Skeleton
                className="table__skeleton"
                variant="rounded"
                width="100%"
              />
            </div>
          </>
        ) : data.length ? (
          data.map((el, i) => (
            <div className="table__tr">
              <Box
                sx={{
                  flex: "0 0 28%",
                }}
                className="table__td"
              >
                <p className="table__text">{++i}</p>
              </Box>
              <Box
                sx={{
                  flex: "0 0 28%",
                }}
                className="table__td"
              >
                <p className="table__text">{el.created_at}</p>
              </Box>
              <Box
                sx={{
                  flex: "0 0 28%",
                }}
                className="table__td"
              >
                <p className="table__text">${el.amount}</p>
              </Box>
              <Box
                sx={{
                  flex: "0 0 16%",
                }}
                className="table__td"
              >
                <Status status={el.status} />
              </Box>
            </div>
          ))
        ) : (
          <p>Not found</p>
        )}
      </Box>
    </Box>
  );
};

export default PaymentHistory;

import React, { useState, useMemo } from "react";
import { Link, redirect } from "react-router-dom";
import Input from "@components/library/UI/Input";
import { useForm } from "react-hook-form";
import { Button } from "@components/library";

import { loginVerify, sendLogin } from "@actions";
import { useDispatch } from "react-redux";

export const Login = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState({});
  const [isTypePassword, setIsTypePassword] = useState(true);
  const [showVerification, setShowVerification] = useState(false);
  const [error, setError] = useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onChange" });

  console.log(errors);

  const onSubmit = (data) => {
    setLoading(true);

    const { email, password, verification } = data;
    if (!showVerification) {
      sendLogin(email, password)
        .then((res) => {
          setLoading(false);
          setShowVerification(true);
          setMyData(res);
        })
        .catch((error) => {
          setLoading(false);
          setError(error.response.data.message);
        });
    } else {
      dispatch(
        loginVerify({
          email,
          password,
          verification,
          hash: myData.hash,
        })
      )
        .then(() => {
          redirect("/");
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setError(error.response.data.message);
        });
    }
  };

  const formChange = () => {
    setError("");
  };

  const checkTypeCode = useMemo(() => {
    let message = "";
    switch (myData["2fa_type"]) {
      case "app":
        message = "Enter Google verification code:";
        break;
      case "sms":
        message = "Enter SMS verification code:";
        break;
      default:
        message = "Enter Email code:";
    }
    return message;
  }, [myData]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onChange={formChange}
      className="auth-form"
    >
      <h2 className="auth-form__title">Log in</h2>
      <p className="auth-form__text">
        First time on Reviarte?&nbsp;
        <Link to="/auth/signup">Sign up</Link>
      </p>
      <Input
        className={"auth-form__inp-wrap"}
        data={{
          label: "Email:",
          type: "email",
          placeholder: "Enter email",
          errors,
          error,
          settings: {
            ...register("email", {
              required: true,
              pattern:
                /^(([a-zA-Z0-9\-._]+)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            }),
          },
          message: "* Invalid email format",
          name: "email",
        }}
      />
      <Input
        className={"auth-form__inp-wrap"}
        data={{
          label: "Password:",
          type: isTypePassword ? "password" : "text",
          placeholder: "Enter password",
          errors,
          error,
          settings: {
            ...register("password", {
              required: true,
              minLength: 8,
              // pattern:
              // /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/,
            }),
          },
          message: "*Password required",
          name: "password",
        }}
      >
        <button
          type="button"
          className={`bo-input__icon bo-input__icon--eye ${
            !isTypePassword ? "open" : ""
          }`}
          onClick={() => setIsTypePassword(!isTypePassword)}
        ></button>
      </Input>
      {showVerification && (
        <Input
          className={"auth-form__inp-wrap bo-input--icon"}
          data={{
            label: checkTypeCode,
            type: "text",
            placeholder: "Enter password",
            errors,
            error,
            settings: {
              ...register("verification", {
                required: true,
                minLength: 6,
                maxLength: 6,
                pattern: /^\d{6}$/,
              }),
            },
            message: "*Invalid code. Please try again",
            name: "verification",
          }}
        >
          <button
            type="button"
            className="bo-input__icon bo-input__icon--left bo-input__icon--key"
          ></button>
        </Input>
      )}

      {error && <p className="auth-form__error">{error}</p>}

      <Link to="/auth/recover-password" className="auth-form__link">
        Forgot password?
      </Link>
      <Button
        // disabled={!isValid}
        className="auth-form__btn"
        type="submit"
        color="orange"
        size="middle"
        isLoading={loading}
      >
        Log in
      </Button>
    </form>
  );
};

export default Login;

import React from "react";
import { CopyIcon } from "@library";

const SmartLink = () => {
  return (
    <div className="main-info">
      <h1 className="main-info__title">Affiliate links</h1>

      <p className="affiliateLinks__text">User a universal Smart-link</p>

      <div className="affiliateLinks__warning">
        <p className="affiliateLinks__warning-text">
          Smart-link is a default link created to get the best conversion in
          registration and deposits.
        </p>
      </div>

      <div className="affiliateLinks__smartL">
        <button type="button" className="affiliateLinks__btn">
          <CopyIcon />
        </button>
        <div className="affiliateLinks__info">
          <a target="_blank" href="#" className="affiliateLinks__smartL-link">
            reviarte.com/refferallink54959485948459454546
          </a>
          <p className="affiliateLinks__smartL-text">
            The link is generated in a way to achieve the best results in
            traffic conversion. Your traffic will be redirected to the website
            pages that ensure the most revenue.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SmartLink;

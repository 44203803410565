import React, { useState } from "react";
import { Grid } from "@mui/material";
import Input from "@components/library/UI/Input";
import { Button } from "@components/library";
import { useForm } from "react-hook-form";
import { changeUserNumber } from "@actions/user/changeUserPassword";
import { useDispatch } from "react-redux";

import "./index.sass";

export const Settings = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isTypePassword, setIsTypePassword] = useState({
    old_password: true,
    password: true,
    confirm_password: true,
  });
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setLoading(true);

    dispatch(changeUserNumber(data))
      .then(() => {
        reset();
      })
      .catch((error) => {
        setError(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formChange = () => {
    setError("");
  };

  return (
    <div className="main-info">
      <div className="changePassword settings">
        <header className="settings__header">
          <h1 className="settings__title">Security Settings</h1>
          <p className="settings__text">
            To change the password, you must enter the old password
          </p>
        </header>

        <form onChange={formChange} onSubmit={handleSubmit(onSubmit)}>
          <Grid container columnSpacing="24px" rowSpacing="24px">
            <Grid item md={6} xs={12}>
              <Input
                className=""
                data={{
                  label: "Your password:",
                  type: isTypePassword.old_password ? "password" : "text",
                  placeholder: "Enter password:",
                  errors,
                  error,
                  settings: {
                    ...register("old_password", {
                      required: true,
                      minLength: 8,
                    }),
                  },
                  message: "*Password required",
                  name: "old_password",
                }}
              >
                <button
                  type="button"
                  className={`bo-input__icon bo-input__icon--eye ${
                    !isTypePassword.old_password ? "open" : ""
                  }`}
                  onClick={() =>
                    setIsTypePassword((prevState) => {
                      return {
                        ...prevState,
                        old_password: !prevState.old_password,
                      };
                    })
                  }
                ></button>
              </Input>
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                display: { md: "block", xs: "none" },
              }}
            />
            <Grid item md={6} xs={12}>
              <Input
                className=""
                data={{
                  label: "New password:",
                  type: isTypePassword.password ? "password" : "text",
                  placeholder: "Enter new password",
                  errors,
                  error,
                  settings: {
                    ...register("password", {
                      required: true,
                      minLength: 8,
                    }),
                  },
                  message: "*Password required",
                  name: "password",
                }}
              >
                <button
                  type="button"
                  className={`bo-input__icon bo-input__icon--eye ${
                    !isTypePassword.password ? "open" : ""
                  }`}
                  onClick={() =>
                    setIsTypePassword((prevState) => {
                      return {
                        ...prevState,
                        password: !prevState.password,
                      };
                    })
                  }
                ></button>
              </Input>
            </Grid>
            <Grid item md={6} xs={12}>
              <Input
                className=""
                data={{
                  label: "Confirm new password:",
                  type: isTypePassword.confirm_password ? "password" : "text",
                  placeholder: "Enter password",
                  errors,
                  error,
                  settings: {
                    ...register("confirm_password", {
                      required: true,
                      minLength: 8,
                      validate: (value) => value === getValues().password,
                    }),
                  },
                  message: "*Password required",
                  name: "confirm_password",
                }}
              >
                <button
                  type="button"
                  className={`bo-input__icon bo-input__icon--eye ${
                    !isTypePassword.confirm_password ? "open" : ""
                  }`}
                  onClick={() =>
                    setIsTypePassword((prevState) => {
                      return {
                        ...prevState,
                        confirm_password: !prevState.confirm_password,
                      };
                    })
                  }
                ></button>
              </Input>
            </Grid>
          </Grid>

          {error && <p className="auth-form__error">{error}</p>}

          <Button
            isLoading={loading}
            className="settings__btn"
            color="orange"
            size="middle"
            type="submit"
          >
            Change password
          </Button>
        </form>
      </div>
    </div>
  );
};

import React from "react";

const MyLabel = ({ label, children }) => {
  return (
    <div className="bo-input">
      <label className="bo-input__label">
        <p className="bo-input__label-text">{label}</p>
        <div className="bo-input__wrap">{children}</div>
      </label>
    </div>
  );
};

export default MyLabel;

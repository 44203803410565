import React, {useEffect, useState} from "react";
import Sidebar from "@components/library/Sidebar";
import { Outlet, useLocation } from "react-router-dom";
import { Header } from "@components/layout/Header";
import { Footer } from "@components/layout/Footer";
import UserData from "@helpers/userData";
import { useSelector } from "react-redux";
import { PreviewLoader } from "@components/library";

const LayoutWithAside = () => {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { loading } = useSelector((state) => state.user);
  
  useEffect(() => {
    setSidebarOpen(false);
  },[location])
  
  return (
    <>
      <UserData />

      {loading ? (
        <PreviewLoader loading={loading} />
      ) : (
        <div className={`base-wrap`}>
          <div className="base-wrap__header">
            <Header setSidebar={setSidebarOpen} />
          </div>

          <div className="base-wrap__content">
            <main className="main-content">
              <Sidebar open={sidebarOpen} setOpen={setSidebarOpen} />
              <div className="main-content__wrapper">
                <Outlet />
              </div>
            </main>
          </div>

          {location.pathname !== "/" && (
            <div className="base-wrap__footer">{<Footer />}</div>
          )}
        </div>
      )}
    </>
  );
};

export default LayoutWithAside;

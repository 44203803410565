import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Skeleton,
  Stack,
} from "@mui/material";
import { Controller } from "react-hook-form";
import axios from "@helpers/axios/private.axios";

import "./index.sass";

export const CountrySelect = ({ control }) => {
  const [country, setCountry] = useState([]);
  const [loading, setLoading] = useState(false);
  const getСountries = async () => {
    try {
      setLoading(true);
      const resp = await axios.get("/common/countries");

      setCountry(resp.data.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getСountries();
  }, []);

  return (
    <div className="bo-input">
      <label className="bo-input__label">
        <p className="bo-input__label-text">Country:</p>
        <div className="bo-input__wrap">
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <Controller
                name="country"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className="bo-select"
                    {...field}
                    variant="standard"
                    MenuProps={{
                      className: "bo-select-content",
                    }}
                  >
                    {loading ? (
                      <Stack spacing="6px">
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width="100%"
                          height={60}
                          className="bo-skeleton"
                        />
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width="100%"
                          height={60}
                          className="bo-skeleton"
                        />
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width="100%"
                          height={60}
                          className="bo-skeleton"
                        />
                      </Stack>
                    ) : (
                      country.map((val, index) => (
                        <MenuItem key={index} value={val.code}>
                          {val.label_en}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                )}
              />
            </FormControl>
          </Box>
        </div>
      </label>
    </div>
  );
};

export default CountrySelect;

import Logo from "@assets/images/Logo-grey.svg";
import { Link } from "react-router-dom";

import "./footer.sass";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__top">
        <Link to={"/"} className="footer__logo">
          <img src={Logo} alt="logo" />
        </Link>

        <div className="footer__text-wrap">
          <p className="footer__text">
            Reviarte © {new Date().getFullYear()}. All Rights Reserved.
          </p>
          <p className="footer__text">General information: info@reviarte.com</p>
          <p className="footer__text">
            Technical support: support@help.reviarte.com
          </p>
        </div>
      </div>
      <div className="footer__bottom">
        <nav className="footer__nav">
          <ul className="footer__list">
            <li className="footer__item">
              <Link to="/about-us" className="footer__link">
                About Us
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/privacy-policy" className="footer__link">
                Privacy Policy
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/cookie-policy" className="footer__link">
                Cookie Policy
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/terms-of-use" className="footer__link">
                Terms of Use
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/help-center" className="footer__link">
                Help Center
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/faq" className="footer__link">
                FAQs
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/amr-policy" className="footer__link">
                AML Policy
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

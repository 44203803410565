import React, { useState } from "react";
import { ALERT, USER } from "../../../../../constants";
import { Button } from "@components/library";
import Input from "@components/library/UI/Input";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "@helpers/axios/private.axios";

import "./index.sass";

const ConfirmWithdrawal = ({ show, setShow, data }) => {
  const [show2FA, setShow2FA] = useState(false);

  const {
    currency,
    amount,
    transaction: { id, payee_wallet },
  } = data;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm();

  const confirm = async (data) => {
    try {
      const { code } = data;
      setLoading(true);

      const resp = await axios.post(`/transactions/transaction/${id}/verify`, {
        code,
      });

      setShow(false);
      dispatch({
        type: ALERT.ALERT_SUCCESS,
        payload: resp.data.message,
      });
      dispatch({
        type: USER.UPDATE_USER_BALANCE,
        payload: resp.data.data.partner_wallet.balance,
      });
    } catch (error) {
      setError(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const send2FACode = async () => {
    try {
      const resp = await axios.get(`/transactions/verify-request`);

      setShow2FA(resp.data.data);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div
      className={`popup confirmWithdrawal-popup ${show ? "active" : ""}`}
      onClick={() => setShow(false)}
    >
      <div onClick={(e) => e.stopPropagation()} className="popup__content">
        <form
          onChange={() => setError(false)}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(confirm)(e);
          }}
          className="confirmWithdrawal"
        >
          <h2 className="confirmWithdrawal__title">Confirm withdrawal</h2>
          <p className="confirmWithdrawal__text">
            Confirm this action to withdrawal
          </p>

          <div className="confirm-info confirm-info--confirm">
            <ul className="confirm-info__list">
              {currency && (
                <li className="confirm-info__item">
                  <div>
                    <p className="confirm-info__text">Currency:</p>
                  </div>
                  <div>
                    <p className="confirm-info__value">{currency}</p>
                  </div>
                </li>
              )}
              {payee_wallet && (
                <li className="confirm-info__item">
                  <div>
                    <p className="confirm-info__text">Wallet Address</p>
                  </div>
                  <div>
                    <p className="confirm-info__value">{payee_wallet}</p>
                  </div>
                </li>
              )}
              {amount && (
                <li className="confirm-info__item">
                  <div>
                    <p className="confirm-info__text">Amount:</p>
                  </div>
                  <div>
                    <p className="confirm-info__value">
                      {amount} {currency}
                    </p>
                  </div>
                </li>
              )}
            </ul>
          </div>

          {show2FA ? (
            <>
              <Input
                className="confirmWithdrawal__inp-wrap"
                data={{
                  label: "Enter the 6-digit Code",
                  type: "text",
                  placeholder: "6-digit Code",
                  errors,
                  error,
                  settings: {
                    ...register("code", {
                      required: true,
                      minLength: 6,
                      maxLength: 6,
                    }),
                  },
                  message: "* Invalid code format",
                  name: "code",
                }}
              />
              {error && <p className="auth-form__error">{error}</p>}
            </>
          ) : (
            <Button
              className="confirmWithdrawal__2fa-btn"
              onClick={send2FACode}
              type="button"
              color="orange"
              size="middle"
            >
              Send code
            </Button>
          )}

          <div className="confirmWithdrawal__wrap-btns">
            <Button
              onClick={() => setShow(false)}
              className="confirmWithdrawal__btn"
              color="grey"
              size="middle"
            >
              Cancel
            </Button>
            <Button
              className="confirmWithdrawal__btn"
              color="orange"
              size="middle"
              disabled={!isValid || !show2FA || error}
              isLoading={loading}
              type="submit"
            >
              Confirm
            </Button>
          </div>
        </form>
        <button
          onClick={() => setShow(false)}
          type="button"
          className="popup__close"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ConfirmWithdrawal;

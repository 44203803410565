import React, { useState } from "react";
import { Tab, Tabs, TabScrollButton } from "@mui/material";
import Balance from "./Balance";
import PaymentHistory from "./PaymentHistory";
import WithdrawalHistory from "./WithdrawalHistory";
export const Wallet = () => {
  const [value, setValue] = useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        allowScrollButtonsMobile
        defaultValue={1}
        className="tabs"
        scrollButtons={false}
        TabScrollButtonComponent={TabScrollButton}
      >
        <Tab label="Balance" value={1} />
        <Tab label="Payment history" value={2} />
        <Tab label="Withdrawal  history" value={3} />
      </Tabs>

      {value === 1 && (
        <div className="tab-content tab-content--scroll">
          <Balance />
        </div>
      )}

      {value === 2 && (
        <div className="tab-content tab-content--scroll">
          <PaymentHistory />
        </div>
      )}

      {value === 3 && (
        <div className="tab-content tab-content--scroll">
          <WithdrawalHistory />
        </div>
      )}
    </>
  );
};

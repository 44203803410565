import axios from "@helpers/axios/public.axios";
import { AUTH } from "../../constants";

export const registration =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      await axios.post("/register", {
        email,
        password,
        name: "test",
        type: "customer",
        hash: "asdfsdafsdfs2",
      });
    } catch (e) {
      dispatch({ type: AUTH.AUTH_ERROR, payload: e.response.data.message });
      throw e;
    }
  };

import React from "react";
import { Button } from "@components/library";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { LogoutIcon, SmsIcon,BurgerIcon } from "@library";
import { removeUserSession } from "../../../../helpers/axios/private.axios";
import {useMediaQuery} from "@mui/material";
import {theme} from "../../../../theme";

const Authorized = ({setSidebar}) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const { full_name } = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const logout = () => {
    removeUserSession();
    navigate("/");
  };

  return (
    <div className="header__right">
      {
        isDesktop ? (
          <ul className="header__nav-list">
            <li className="header__nav-item">
              <Button
                type="link"
                className="header__support"
                to="/auth/login"
                color="black"
                size="mini"
              >
                <SmsIcon />
                Support
              </Button>
            </li>
            <li className="header__nav-item">
              <Link to="/settings" className="header__link">
                {full_name}
              </Link>
            </li>
            <li className="header__nav-item">
              <button onClick={logout} type="button" className="header__logout">
                <LogoutIcon />
              </button>
            </li>
          </ul>   
        ) : (
            <button
              onClick={() => setSidebar(true)}
              className="header__burger"
              type="button"
            >
              <BurgerIcon/>
            </button>
        )
      }
    </div>
  );
};

export default Authorized;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "@actions/user";
import { useNavigate } from "react-router-dom";
import { removeUserSession } from "@helpers/axios/private.axios";
const UserData = () => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserData()).then((data) => {
      const { is_banned = false } = data;
      if (is_banned) {
        removeUserSession();
        navigate("/");
      }
    });
  }, [token]);

  return null;
};

export default UserData;

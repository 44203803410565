import React, { useState } from "react";
import Arrow from "@assets/images/icons/arrow-left.svg";
import { useForm } from "react-hook-form";
import Input from "@components/library/UI/Input";
import { Link } from "react-router-dom";
import { Button } from "@components/library";
import { useDispatch } from "react-redux";
import { recoverPassword } from "@actions";

export const RecoverPassword = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const {
    register,
    reset,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    setLoading(true);

    const { email } = data;
    dispatch(recoverPassword({ email }))
      .then(() => {
        setLoading(false);
        reset();
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        console.log(e);
      });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onChange={() => setError(false)}
      className="auth-form"
    >
      <Link to="/auth/login" className="auth-form__back">
        <img src={Arrow} alt="back" />
        Back to Login
      </Link>
      <h2 className="auth-form__title">Password reset</h2>
      <p className="auth-form__text">
        Please enter your email address to receive a password reset code
      </p>
      <Input
        className={"auth-form__inp-wrap"}
        data={{
          label: "Email:",
          type: "email",
          placeholder: "Enter email",
          errors,
          error,
          settings: {
            ...register("email", {
              required: true,
              pattern:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            }),
          },
          message: "* Invalid email format",
          name: "email",
        }}
      />

      {error && <p className="auth-form__error">Incorrect email address</p>}

      <Button
        disabled={!isValid}
        className="auth-form__btn"
        type="submit"
        color="orange"
        size="middle"
        isLoading={loading}
      >
        Reset password
      </Button>
    </form>
  );
};

export default RecoverPassword;

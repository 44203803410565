import { createTheme } from "@mui/material";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 428,
      b680: 680,
      md: 768,
      lg: 958,
      xl: 1280,
      b1440: 1440,
    },
  },
});

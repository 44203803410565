import React from "react";
import { Header } from "../Header/";
import {useLocation} from "react-router-dom";

import { Footer } from "../Footer/";
import "./layout.sass";

export const Layout = ({ children }) => {
  const location = useLocation();

  return (
    <div className={`base-wrap`}>
      <div className="base-wrap__header">
        <Header />
      </div>

      <div className="base-wrap__content">{children}</div>


      {location.pathname !== "/" && (
        <div className="base-wrap__footer">{<Footer />}</div>
      )}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import "./index.sass";
import { Grid } from "@mui/material";
import axios from "@helpers/axios/private.axios";

export const Offers = () => {
  const [currentOffer, setCurrentOffer] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getOffers = async () => {
      try {
        setLoading(true);
        const resp = await axios.get("/offer");

        console.log(resp.data);

        setCurrentOffer(resp.data.data.first_deposit_current_month);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    };

    getOffers();
  }, []);

  const calculateProgress = (offer, min, max) => {
    if (offer > 199) {
      return 100;
    }

    if (offer <= min) return 0;
    if (offer >= max) return 100;
    return ((offer - min) / (max - min)) * 100;
  };

  return (
    <div className="main-info offers">
      <h1 className="main-info__title">Offers</h1>

      <Grid sx={{ opacity: loading ? 0.5 : 1 }} container spacing="15px">
        <Grid b1440={2.4} xs={12} item>
          <div className="offers__info">
            <div className="offers__info-wrap">
              <h3 className="offers__info-title">Monthly Conditions</h3>
            </div>
            <div className="offers__info-wrap">
              <h3 className="offers__info-title">Revenue Sharing</h3>
            </div>
            <div className="offers__info-wrap">
              <h3 className="offers__info-title">Deposit Sharing</h3>
            </div>
            <div className="offers__info-wrap">
              <h3 className="offers__info-title">Volume Sharing</h3>
            </div>
            <div className="offers__info-wrap">
              <h3 className="offers__info-title">Turnover Sharing</h3>
            </div>
            <div className="offers__info-wrap">
              <h3 className="offers__info-title">Personal promos</h3>
            </div>
          </div>
        </Grid>
        <Grid b1440={2.4} b680={6} xs={12} item>
          <div className="offers__progress">
            <div
              style={{ width: `${calculateProgress(currentOffer, 3, 29)}%` }}
              className="offers__progress-indicator"
            ></div>
          </div>
          <div
            className={`offer-card${
              currentOffer > 2 && currentOffer < 30 ? " offer-card--active" : ""
            }`}
          >
            <h2 className="offer-card__title">Good start</h2>

            <div className="offer-card__info-wrap">
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Monthly Conditions</h3>
                <h3 className="offer-card__info-title">3 - 29 FTD</h3>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Revenue Sharing</h3>
                <h3 className="offer-card__info-title">55%</h3>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Deposit Sharing</h3>
                <h3 className="offer-card__info-title">60% of first deposit</h3>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Volume Sharing</h3>
                <h3 className="offer-card__info-title">
                  50% revenue share +2.5% net turnover
                </h3>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Turnover Sharing</h3>
                <h3 className="offer-card__info-title">4.5% net turnover</h3>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Personal promos</h3>
                <h3 className="offer-card__info-title">-</h3>
              </div>
            </div>
          </div>
        </Grid>
        <Grid b1440={2.4} b680={6} xs={12} item>
          <div className="offers__progress">
            <div
              style={{ width: `${calculateProgress(currentOffer, 30, 99)}%` }}
              className="offers__progress-indicator"
            ></div>
          </div>
          <div
            className={`offer-card${
              currentOffer > 29 && currentOffer < 100
                ? " offer-card--active"
                : ""
            }`}
          >
            <h2 className="offer-card__title">Power +</h2>

            <div className="offer-card__info-wrap">
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Monthly Conditions</h3>
                <h3 className="offer-card__info-title">30 - 99 FTD</h3>
                <p className="offer-card__text">
                  (or from 30 - 99 FTD with total amount over $1500)
                </p>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Revenue Sharing</h3>
                <h3 className="offer-card__info-title">65%</h3>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Deposit Sharing</h3>
                <h3 className="offer-card__info-title">
                  60% of 3 first deposit
                </h3>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Volume Sharing</h3>
                <h3 className="offer-card__info-title">
                  55% revenue share +3% net turnover
                </h3>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Turnover Sharing</h3>
                <h3 className="offer-card__info-title">5% net turnover</h3>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Personal promos</h3>
                <h3 className="offer-card__info-title">up to +75%</h3>
              </div>
            </div>
          </div>
        </Grid>
        <Grid b1440={2.4} b680={6} xs={12} item>
          <div className="offers__progress">
            <div
              style={{ width: `${calculateProgress(currentOffer, 100, 199)}%` }}
              className="offers__progress-indicator"
            ></div>
          </div>
          <div
            className={`offer-card${
              currentOffer > 99 && currentOffer < 200
                ? " offer-card--active"
                : ""
            }`}
          >
            <h2 className="offer-card__title">Golden</h2>

            <div className="offer-card__info-wrap">
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Monthly Conditions</h3>
                <h3 className="offer-card__info-title">100 - 199 FTD</h3>
                <p className="offer-card__text">
                  (or from 100 - 199 FTD with total amount over $5000)
                </p>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Revenue Sharing</h3>
                <h3 className="offer-card__info-title">75%</h3>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Deposit Sharing</h3>
                <h3 className="offer-card__info-title">
                  60% of 7 first deposit
                </h3>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Volume Sharing</h3>
                <h3 className="offer-card__info-title">
                  60% revenue share +3% net turnover
                </h3>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Turnover Sharing</h3>
                <h3 className="offer-card__info-title">5.5% net turnover</h3>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Personal promos</h3>
                <h3 className="offer-card__info-title">up to +85%</h3>
              </div>
            </div>
          </div>
        </Grid>
        <Grid b1440={2.4} b680={6} xs={12} item>
          <div className="offers__progress">
            <div
              style={{
                width: `${calculateProgress(currentOffer, 200, Infinity)}%`,
              }}
              className="offers__progress-indicator"
            ></div>
          </div>
          <div
            className={`offer-card${
              currentOffer > 199 ? " offer-card--active" : ""
            }`}
          >
            <h2 className="offer-card__title">Platinum</h2>

            <div className="offer-card__info-wrap">
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Monthly Conditions</h3>
                <h3 className="offer-card__info-title">200+ FTD</h3>
                <p className="offer-card__text">
                  (or from 200+ FTD with total amount over $10 000)
                </p>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Revenue Sharing</h3>
                <h3 className="offer-card__info-title">85%</h3>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Deposit Sharing</h3>
                <h3 className="offer-card__info-title">60% of all deposit</h3>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Volume Sharing</h3>
                <h3 className="offer-card__info-title">
                  65% revenue share +3% net turnover
                </h3>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Turnover Sharing</h3>
                <h3 className="offer-card__info-title">6% net turnover</h3>
              </div>
              <div className="offer-card__info-item">
                <h3 className="offers__info-title">Personal promos</h3>
                <h3 className="offer-card__info-title">up to +90%</h3>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      <div className="offers__bottom">
        <Grid container spacing="15px">
          <Grid b1440={2.4} item>
            <h3 className="offers__bottom-title">Support</h3>
          </Grid>
          <Grid md="auto" xs={12} item>
            <p className="offers__bottom-text">
              Personal assistance guaranteed
            </p>
          </Grid>
        </Grid>
        <Grid container spacing="15px">
          <Grid b1440={2.4} item>
            <h3 className="offers__bottom-title">Payout frequecy</h3>
          </Grid>
          <Grid md="auto" xs={12} item>
            <p className="offers__bottom-text">Every Monday</p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Offers;

import React from "react";

export const WarningIcon = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 17C13.1421 17 16.5 13.6421 16.5 9.5C16.5 5.35786 13.1421 2 9 2C4.85786 2 1.5 5.35786 1.5 9.5C1.5 13.6421 4.85786 17 9 17Z"
        fill="#0084FF"
      />
      <path
        d="M6.81738 7.24994C6.99371 6.74869 7.34175 6.32602 7.79985 6.05679C8.25795 5.78756 8.79655 5.68914 9.32027 5.77897C9.84398 5.8688 10.319 6.14108 10.6612 6.54758C11.0034 6.95409 11.1907 7.46858 11.1899 7.99994C11.1899 9.49994 8.93988 10.2499 8.93988 10.2499"
        stroke="#232323"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 13.25H9.0075"
        stroke="#232323"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { ArrowLeft, Button, StrokeHelpIcon } from "@library";
import ETH from "@assets/images/icons/Ethereum (ETH).svg";
import TRX from "@assets/images/icons/trx.svg";
import BNB from "@assets/images/icons/bnb.svg";
import BTC from "@assets/images/icons/btc.svg";
import USDT from "@assets/images/icons/usdt.svg";
import { debounce, Grid, Tooltip } from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "@helpers/axios/private.axios";
import "./index.sass";
import { useDispatch, useSelector } from "react-redux";
import { ALERT } from "../../../../constants";
import { Transition } from "react-transition-group";
import ConfirmWithdrawal from "./ConfirmWithdrawal";
const currency = [
  { img: ETH, name: "ETH" },
  { img: TRX, name: "TRX" },
  { img: BNB, name: "BNB" },
  { img: BTC, name: "BTC" },
  { img: USDT, name: "USDT" },
];

export const Withdrawal = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [validating, setValidating] = useState(false);
  const [confirmData, setConfirmData] = useState({});
  const [info, setInfo] = useState({
    withdrawal: "$10",
    received: "$9",
    fee: "0.5",
    can_be_requested: false,
  });
  const [currentCrypto, setCurrentCrypto] = useState(currency[0].name);
  const dispatch = useDispatch();
  const { balance = "" } = useSelector((state) => state.user);
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    clearErrors,
    watch,
    setError,
    reset,
  } = useForm({ mode: "onChange" });

  const withdrawal = async () => {
    const { amount, bank_account } = watch();

    try {
      const resp = await axios.post(`/transactions/request`, {
        amount,
        bank_account,
        currency: currentCrypto,
      });

      setConfirmData(resp.data.data);
      setShowConfirm(true);
    } catch (error) {
      console.error(error);
      dispatch({
        type: ALERT.ALERT_ERROR,
        payload: error.response.data.message,
      });
    }

    console.log(reset, amount, bank_account);
  };

  const validateForm = useCallback(
    debounce(async () => {
      const { amount, bank_account } = watch();

      try {
        clearErrors();
        const resp = await axios.post(`/transactions/validate`, {
          amount,
          bank_account,
        });
        console.log(resp);
        setInfo(resp.data.data);
      } catch (e) {
        const errors = e.response.data.errors;

        if (!errors) return;

        [...Object.keys(errors)].forEach((error) => {
          if (errors?.[error]) {
            setError(error, {
              type: "custom",
              message: errors?.[error][0],
            });
          }
        });
      } finally {
        setValidating(false);
      }
    }, 500),
    [currentCrypto]
  );

  const formChange = () => {
    validateForm();
  };

  const onChangeCrypto = (code) => {
    setCurrentCrypto(code);
    validateForm();
  };

  return (
    <>
      <form
        onChange={formChange}
        onSubmit={handleSubmit(withdrawal)}
        className="withdrawal"
      >
        <div className="top-balance">
          <div className="top-balance__wrapper">
            <div className="top-balance__left">
              <Link to={"/wallet"} className="top-balance__link">
                <ArrowLeft className="top-balance__link-icon" />
                Back to Wallet
              </Link>
              <h1 className="top-balance__title">Available balance</h1>
            </div>
            <div className="top-balance__right">
              <p className="top-balance__balance">{balance}</p>
            </div>
          </div>
        </div>

        <div className="withdrawal__content">
          <div className="withdrawal__currency">
            <h3 className="withdrawal__title">Select currency:</h3>

            <ul className="withdrawal__list">
              {currency &&
                currency.map((el, i) => {
                  return (
                    <li key={i} className="withdrawal__item">
                      <button
                        onClick={() => onChangeCrypto(el.name)}
                        type="button"
                        className={`crypto-btn ${
                          currentCrypto === el.name ? "active" : ""
                        }`}
                      >
                        <img
                          className="crypto-btn__icon"
                          src={el.img}
                          alt="#"
                        />
                        <p className="crypto-btn__text">{el.name}</p>
                      </button>
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className="withdrawal__inputs">
            <Grid container columnSpacing={"1rem"}>
              <Grid item lg={4} b680={6} xs={12}>
                <div className="bo-input fiat__inp-wrap">
                  <label className="bo-input__label">
                    <p className="bo-input__label-text">Enter Amount:</p>
                    <div className="bo-input__wrap">
                      <input
                        type="number"
                        placeholder="100$"
                        className={`${
                          errors.amount ? "error" : ""
                        } bo-input__input`}
                        {...register("amount", {
                          required: true,
                        })}
                      />
                      {errors.amount && (
                        <p className="bo-input__error">
                          {errors.amount.message}
                        </p>
                      )}
                    </div>
                  </label>
                </div>
              </Grid>
              <Grid item lg={4} b680={6} xs={12}>
                <div className="bo-input fiat__inp-wrap">
                  <label className="bo-input__label">
                    <p className="bo-input__label-text">
                      Enter Wallet Address:
                    </p>
                    <div className="bo-input__wrap">
                      <input
                        type="text"
                        placeholder="Enter address"
                        className={`${
                          errors.bank_account ? "error" : ""
                        } bo-input__input`}
                        {...register("bank_account", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors.bank_account && (
                      <p className="bo-input__error">
                        {errors.bank_account.message}
                      </p>
                    )}
                  </label>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="withdrawal__withdrawal">
            <div className="tooltip withdrawal__tooltip">
              <h3 className="withdrawal__title">Withdrawal</h3>
              <Tooltip
                title="You can withdraw money from your balance to your bank card or electronic purse you used for depositing.
            You can request withdrawal any time. Your withdrawal requests are processed in 3 business days."
                placement="right-start"
                className="tooltip__icon"
                slotProps={{
                  tooltip: {
                    className: "tooltip__popper tooltip__popper--middle",
                  },
                }}
              >
                <span className="withdrawal__tooltip-i">
                  <StrokeHelpIcon />
                </span>
              </Tooltip>
            </div>

            <Grid container columnSpacing={"1rem"}>
              <Grid item xl={4} lg={6} xs={12}>
                <div
                  className={`crypto-info${
                    !(isValid && !Object.keys(errors).length && !validating)
                      ? " crypto-info--disabled"
                      : ""
                  }`}
                >
                  <ul className="crypto-info__list">
                    <li className="crypto-info__item">
                      <div>
                        <p className="crypto-info__text">Fee:</p>
                      </div>
                      <div>
                        <p className="crypto-info__value crypto-info__value--bg">
                          {info.fee}%
                        </p>
                      </div>
                    </li>
                    <li className="crypto-info__item">
                      <div>
                        <p className="crypto-info__text">Will be withdrawal:</p>
                      </div>
                      <div>
                        <p className="crypto-info__value">{info.withdrawal}</p>
                      </div>
                    </li>
                    <li className="crypto-info__item">
                      <div>
                        <p className="crypto-info__text">Will be received:</p>
                      </div>
                      <div>
                        <p className="crypto-info__value">{info.received}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>

            <Button
              className="crypto-withdrawal__btn"
              size="middle"
              color="orange"
              type="submit"
              disabled={
                !(isValid && !Object.keys(errors).length && !validating)
              }
            >
              Withdrawal funds
            </Button>
          </div>
        </div>
      </form>
      <Transition in={showConfirm} timeout={500} mountOnEnter unmountOnExit>
        <ConfirmWithdrawal
          show={showConfirm}
          setShow={setShowConfirm}
          data={confirmData}
        />
      </Transition>
    </>
  );
};

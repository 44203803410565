import React from "react";

export const BurgerIcon = () => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.5H21"
        stroke="#707070"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M1 9H21"
        stroke="#707070"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M1 16.5H21"
        stroke="#707070"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default BurgerIcon;

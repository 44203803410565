export const GLOBALS = {
  local_state_name: "bo_state",
  state_localization: "localization",
};

// Auth
export const AUTH = {
  AUTH_REQUEST: "AUTH_REQUEST",
  AUTH_RESPONSE: "AUTH_RESPONSE",
  AUTH_LOADED: "AUTH_LOADED",
  AUTH_ERROR: "AUTH_ERROR",
  AUTH_LOGOUT: "AUTH_LOGOUT",
  AUTH_SET_USER: "AUTH_SET_USER",
  AUTH_FAILURE: "AUTH_FAILURE",
  AUTH_IS_AUTH: "AUTH_IS_AUTH",
};

// Locales
export const LOCALE = {
  CHANGE_LOCALE: "CHANGE_LOCALE",
  LOADING_LOCALE: "LOADING_LOCALE",
  END_LOADING_LOCALE: "END_LOADING_LOCALE",
};

//user
export const USER = {
  USER_REQUEST: "USER_REQUEST",
  USER_RESPONSE: "USER_RESPONSE",
  USER_LOADED: "USER_LOADED",
  USER_ERROR: "USER_ERROR",
  USER_FIELD: "USER_FIELD",
  UPDATE_USER_BALANCE: "UPDATE_USER_BALANCE",
};

//alert
export const ALERT = {
  ALERT_SUCCESS: "ALERT_SUCCESS",
  ALERT_ERROR: "ALERT_ERROR",
  ALERT_HIDE: "ALERT_HIDE",
};

export const WALLET = {
  SHOW_WITHDRAWAL: "SHOW_WITHDRAWAL",
  HIDE_WITHDRAWAL: "HIDE_WITHDRAWAL",
};

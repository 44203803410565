import { ALERT, USER } from "../../constants";
import axios from "@helpers/axios/private.axios";

export const getUserData = () => async (dispatch) => {
  try {
    dispatch({ type: USER.USER_REQUEST });
    const response = await axios.get("/users/me");

    const {
      user,
      user: { is_banned },
      balance,
    } = response.data.data;

    dispatch({
      type: USER.USER_RESPONSE,
      payload: { user },
    });
    dispatch({
      type: USER.UPDATE_USER_BALANCE,
      payload: balance,
    });

    if (is_banned) {
      dispatch({ type: ALERT.ALERT_ERROR, payload: "You are banned!" });
    }

    return { is_banned };
  } catch (error) {
    console.error(error);
  }
};

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { theme } from "./theme";
import { AuthWrapper } from "@components/common/AuthWrapper";
import { ThemeProvider } from "@mui/material";
import store from "./redux/store";
import "normalize.css";
import "./styles/globals.sass";
/*
    Файл для глобальних конфігурацій, врапперів і т.д
*/

export const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AuthWrapper />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};

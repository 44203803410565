import React from "react";

export const AffiliateIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 6.125H7.375C6.68464 6.125 6.125 6.68464 6.125 7.375V13C6.125 13.6904 6.68464 14.25 7.375 14.25H13C13.6904 14.25 14.25 13.6904 14.25 13V7.375C14.25 6.68464 13.6904 6.125 13 6.125Z"
        stroke="#707070"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.625 9.875H3C2.66848 9.875 2.35054 9.7433 2.11612 9.50888C1.8817 9.27446 1.75 8.95652 1.75 8.625V3C1.75 2.66848 1.8817 2.35054 2.11612 2.11612C2.35054 1.8817 2.66848 1.75 3 1.75H8.625C8.95652 1.75 9.27446 1.8817 9.50888 2.11612C9.7433 2.35054 9.875 2.66848 9.875 3V3.625"
        stroke="#707070"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { updateUser } from "@actions";
import { Input, Button, CountrySelect } from "@library";

export const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const {
    full_name = "",
    email,
    country = "",
    nickname = "",
    id,
  } = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    // getValues,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      full_name,
      email,
      country,
      nickname,
      userid: id,
    },
  });

  const onSubmit = (formData) => {
    setLoading(true);

    const { email, full_name, country, nickname } = formData;
    const data = {
      email,
      values: {
        full_name,
        country,
        nickname,
      },
    };

    dispatch(updateUser(data))
      .catch((error) => {
        setError(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formChange = () => {
    setError("");
  };

  return (
    <div className="main-info">
      <h1 className="main-info__title">KimJons123</h1>

      <form onChange={formChange} onSubmit={handleSubmit(onSubmit)}>
        <Grid container columnSpacing="24px" rowSpacing="15px">
          <Grid md={6} xs={12} item>
            <Input
              data={{
                label: "Email:",
                type: "email",
                placeholder: "qwerty@gmail.com",
                errors,
                settings: {
                  ...register("email", {
                    required: true,
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  }),
                },
                message: "* Invalid email format",
                name: "email",
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              data={{
                label: "User ID:",
                type: "text",
                placeholder: "123456",
                errors,
                settings: {
                  ...register("userid", {
                    disabled: true,
                  }),
                },
                name: "userid",
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CountrySelect control={control} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              className="profile__inp-wrap"
              data={{
                label: "Full name:",
                type: "text",
                placeholder: "Full name",
                errors,
                settings: {
                  ...register("full_name", {
                    required: true,
                    pattern: /^[\p{L} ,.'-]+$/u,
                  }),
                },
                name: "full_name",
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              className="profile__inp-wrap"
              data={{
                label: "Nickname:",
                type: "text",
                placeholder: "KimJons123",
                errors,
                settings: {
                  ...register("nickname", {
                    required: true,
                  }),
                },
                name: "nickname",
              }}
            />
          </Grid>
        </Grid>

        {error && <p className="auth-form__error">{error}</p>}

        <Button
          isLoading={loading}
          className="settings__btn"
          color="orange"
          size="middle"
          type="submit"
        >
          Save
        </Button>
      </form>
    </div>
  );
};

export default Profile;

export const StrokeHelpIcon = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
        stroke="#707070"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.54492 5.00028C4.66247 4.66612 4.8945 4.38434 5.1999 4.20485C5.5053 4.02536 5.86437 3.95975 6.21351 4.01964C6.56265 4.07953 6.87933 4.26105 7.10746 4.53205C7.33559 4.80305 7.46045 5.14605 7.45992 5.50028C7.45992 6.50028 5.95992 7.00028 5.95992 7.00028"
        stroke="#707070"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 9H6.00583"
        stroke="#707070"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

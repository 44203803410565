import React from "react";

export const ProfileIcon = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 3.99804C10.5 5.65396 9.157 6.99609 7.5 6.99609C5.843 6.99609 4.5 5.65396 4.5 3.99804C4.5 2.34212 5.843 1 7.5 1C9.157 1 10.5 2.34212 10.5 3.99804Z"
        stroke="#707070"
        strokeLinecap="square"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 14.9909H2.5C2.5 14.2808 2.5 13.6053 2.5 12.9936C2.5 11.3368 3.84315 9.99414 5.5 9.99414H9.5C11.1569 9.99414 12.5 11.3368 12.5 12.9936C12.5 13.6053 12.5 14.2808 12.5 14.9909Z"
        stroke="#707070"
        strokeLinecap="square"
      />
    </svg>
  );
};

import React from "react";
import { Button } from "@components/library";
import { SmsIcon } from "@library";

import "../header.sass";
const Unauthorized = () => {
  return (
    <div className="header__right">
      <nav>
        <ul className="header__nav-list">
          <li className="header__nav-item">
            <Button
              type="link"
              className="header__login"
              to="/auth/login"
              color="black"
              size="mini"
            >
              Login
            </Button>
          </li>
          <li className="header__nav-item">
            <Button
              type="link"
              className="header__signUp"
              to="/auth/signup"
              color="orange"
              size="mini"
            >
              Sign up
            </Button>
          </li>
          <li className="header__nav-item">
            <Button
              type="link"
              className="header__support"
              to="/auth/login"
              color="black"
              size="mini"
            >
              <SmsIcon />
              Support
            </Button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Unauthorized;

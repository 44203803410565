import React from "react";
import { Layout } from "@components/layout/Layout";
import CheckIcon from "../../library/Icons/CheckIcon";
import {Link} from "react-router-dom";
import Etherium from '@assets/images/main/Etherium.png';
import LiteCoin from '@assets/images/main/LiteCoin.png';
import Monero from '@assets/images/main/Monero.png';
import Bitcoin from '@assets/images/main/Bitcoin.png';

import "./index.sass";

export const Main = () => {
  
  return (
    <Layout>
      <main className="main-start">
        <img className="main-start__dec1" src={Etherium} alt="Etherium"/>
        <img className="main-start__dec2" src={LiteCoin} alt="LiteCoin"/>
        <img className="main-start__dec3" src={Monero} alt="Monero"/>
        <img className="main-start__dec4" src={Bitcoin} alt="Bitcoin"/>

        <div className="main-start__content">
          <h1 className="main-start__title">Become <br/> a partner Reviarte</h1>

          <ul className="main-start__list">
            <li className="main-start__item">
              <CheckIcon/>
              <p className="main-start__item-text">The best conditions on the market</p>
            </li>
            <li className="main-start__item">
              <CheckIcon/>
              <p className="main-start__item-text">Available worldwide</p>
            </li>
            <li className="main-start__item">
              <CheckIcon/>
              <p className="main-start__item-text">Up to 80% commission</p>
            </li>
          </ul>

          <Link to="/" className="main-start__btn">Become our partner</Link>
        </div>
      </main>
    </Layout>
  );
};

export default Main;

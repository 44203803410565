import React from "react";
import Unverified from "@assets/images/icons/check.svg";
import Verified from "@assets/images/icons/success.svg";
import Pending from "@assets/images/icons/pending.svg";

import "./index.sass";

export const Status = ({ status, className }) => {
  const statusMap = {
    success: "verified",
    pending: "pending",
    unverified: "unverified",
    2: "verified",
    1: "pending",
    0: "unverified",
  };

  const textMap = {
    success: "Successful",
    pending: "In progress",
    unverified: "Failed",
    2: "Successful",
    1: "In progress",
    0: "Failed",
  };

  const iconMap = {
    success: Verified,
    pending: Pending,
    unverified: Unverified,
    0: Unverified,
    2: Verified,
    1: Pending,
  };

  return (
    <div
      className={`status ${statusMap[status] || statusMap.default}${
        className ? ` ${className}` : ""
      }`}
    >
      <img
        className="status__icon"
        src={`${iconMap[status] || statusMap.default}`}
      />
      <p className="status__text">{textMap[status]}</p>
    </div>
  );
};

export default Status;

import React, { useEffect, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import { Status } from "@library";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import Copy from "@assets/images/icons/copy.svg";
import axios from "@helpers/axios/private.axios";

const WithdrawalHistory = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const resp = await axios.get("/transactions/withdrawal");

        setData(resp.data.data);
        setLoading(false);
      } catch (e) {
        console.log(e, data, loading);
      }
    };

    getData();
  }, []);

  return (
    <Box sx={{ overflowX: "auto" }}>
      <Box sx={{ minWidth: 1054 }} className="table">
        <div className="table__tr table__tr--head">
          <Box
            sx={{
              flex: "0 0 5%",
            }}
            className="table__td"
          >
            <p className="table__text">ID</p>
          </Box>
          <Box
            sx={{
              flex: "0 0 16%",
            }}
            className="table__td"
          >
            <p className="table__text">Date of creation</p>
          </Box>
          <Box
            sx={{
              flex: "0 0 16%",
            }}
            className="table__td"
          >
            <p className="table__text">Date of update</p>
          </Box>
          <Box
            sx={{
              flex: "0 0 7%",
            }}
            className="table__td"
          >
            <p className="table__text">Amount</p>
          </Box>
          <Box
            sx={{
              flex: "0 0 12%",
            }}
            className="table__td"
          >
            <p className="table__text">Payment Method</p>
          </Box>
          <Box
            sx={{
              flex: "0 0 13%",
            }}
            className="table__td"
          >
            <p className="table__text">Wallet address</p>
          </Box>
          <Box
            sx={{
              flex: "0 0 15%",
            }}
            className="table__td"
          >
            <p className="table__text">TX Hash</p>
          </Box>
          <Box
            sx={{
              flex: "0 0 15%",
            }}
            className="table__td"
          >
            <p className="table__text">Status</p>
          </Box>
        </div>

        {loading ? (
          <>
            <div className="table__tr">
              <Skeleton
                className="table__skeleton"
                variant="rounded"
                width="100%"
              />
            </div>
            <div className="table__tr">
              <Skeleton
                className="table__skeleton"
                variant="rounded"
                width="100%"
              />
            </div>
            <div className="table__tr">
              <Skeleton
                className="table__skeleton"
                variant="rounded"
                width="100%"
              />
            </div>
          </>
        ) : data.length ? (
          data.map((el, i) => (
            <div className="table__tr">
              <Box
                sx={{
                  flex: "0 0 5%",
                }}
                className="table__td"
              >
                <p className="table__text">{++i}</p>
              </Box>
              <Box
                sx={{
                  flex: "0 0 16%",
                }}
                className="table__td"
              >
                <p className="table__text">{el.created_at}</p>
              </Box>
              <Box
                sx={{
                  flex: "0 0 16%",
                }}
                className="table__td"
              >
                <p className="table__text">{el.updated_at}</p>
              </Box>
              <Box
                sx={{
                  flex: "0 0 7%",
                }}
                className="table__td"
              >
                <p className="table__text">${el.amount}</p>
              </Box>
              <Box
                sx={{
                  flex: "0 0 12%",
                }}
                className="table__td"
              >
                <p className="table__text">{el.gateway_title}</p>
              </Box>
              <Box
                sx={{
                  flex: "0 0 13%",
                }}
                className="table__td"
              >
                <a href="#" className="table__text  table__text--blue">
                  a1075db...4hk
                </a>
              </Box>
              <Box
                sx={{
                  flex: "0 0 15%",
                }}
                className="table__td"
              >
                <div className="table__wrap">
                  {el.hash && (
                    <>
                      <a
                        target="_blank"
                        href={el.invoice_link}
                        className="table__text table__text--blue"
                      >
                        {el.hash.substring(0, 12) +
                          " ..." +
                          el.hash.substring(el.hash.length - 10)}
                      </a>
                      <CopyToClipboard text={el.hash}>
                        <button type="button" className="table__copy">
                          <img src={Copy} />
                        </button>
                      </CopyToClipboard>
                    </>
                  )}
                </div>
              </Box>
              <Box
                sx={{
                  flex: "0 0 15%",
                }}
                className="table__td"
              >
                <Status status={el.status} className="status--full" />
              </Box>
            </div>
          ))
        ) : (
          <p>Not found</p>
        )}
      </Box>
    </Box>
  );
};

export default WithdrawalHistory;

import React from 'react';

const CheckIcon = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M10.1314 1.16181C10.3509 1.00173 10.6486 1.00173 10.8681 1.16181L12.8918 2.63804L15.3968 2.63327C15.6684 2.63276 15.9093 2.80776 15.9927 3.06627L16.7623 5.45011L18.7916 6.91862C19.0117 7.07787 19.1037 7.36103 19.0192 7.61922L18.2406 10.0001L19.0192 12.3809C19.1037 12.6391 19.0117 12.9223 18.7916 13.0815L16.7623 14.55L15.9927 16.9339C15.9093 17.1924 15.6684 17.3674 15.3968 17.3669L12.8918 17.3621L10.8681 18.8384C10.6486 18.9984 10.3509 18.9984 10.1314 18.8384L8.10766 17.3621L5.60271 17.3669C5.33107 17.3674 5.0902 17.1924 5.00675 16.9339L4.23721 14.5501L2.20786 13.0815C1.98779 12.9223 1.89579 12.6391 1.98022 12.3809L2.75883 10.0001L1.98022 7.61922C1.89579 7.36104 1.98779 7.07787 2.20786 6.91862L4.2372 5.45011L5.00675 3.06627C5.0902 2.80776 5.33107 2.63276 5.60271 2.63327L8.10766 2.63804L10.1314 1.16181ZM10.4997 2.44036L8.67919 3.76837C8.5719 3.84663 8.44247 3.88868 8.30967 3.88843L6.05624 3.88414L5.36397 6.02863C5.32317 6.15501 5.24318 6.2651 5.13559 6.34296L3.31001 7.66402L4.01044 9.80581C4.05172 9.93204 4.05172 10.0681 4.01044 10.1944L3.31001 12.3361L5.13559 13.6572C5.24318 13.7351 5.32317 13.8452 5.36397 13.9715L6.05624 16.116L8.30968 16.1117C8.44247 16.1115 8.5719 16.1536 8.67918 16.2318L10.4997 17.5598L12.3203 16.2318C12.4276 16.1536 12.557 16.1115 12.6898 16.1117L14.9432 16.116L15.6355 13.9715C15.6763 13.8452 15.7563 13.7351 15.8639 13.6572L17.6894 12.3361L16.989 10.1943C16.9477 10.0681 16.9477 9.93204 16.989 9.80582L17.6894 7.66402L15.8639 6.34296C15.7563 6.2651 15.6763 6.155 15.6355 6.02863L14.9432 3.88414L12.6898 3.88843C12.557 3.88868 12.4276 3.84663 12.3203 3.76837L10.4997 2.44036Z"
            fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.2749 7.47481C14.519 7.71888 14.519 8.11461 14.2749 8.35869L10.1083 12.5254C9.8642 12.7694 9.46848 12.7694 9.2244 12.5254L7.14107 10.442C6.89699 10.1979 6.89699 9.80222 7.14107 9.55814C7.38514 9.31406 7.78087 9.31406 8.02495 9.55814L9.66634 11.1995L13.3911 7.47481C13.6351 7.23073 14.0309 7.23073 14.2749 7.47481Z"
            fill="white"/>
    </svg>
  );
};

export default CheckIcon;

import React from "react";
import { Button } from "@components/library";
import Updated from "@assets/images/icons/shield-tick.svg";

export const UpdatedPassword = () => {
  return (
    <div className="completed">
      <picture className="completed__icon">
        <img src={Updated} alt="shield tick" />
      </picture>

      <h1 className="completed__title">Password updated!</h1>

      <p className="completed__text">
        Your password has been changed successfully.
        <br />
        Use your new password to log in.
      </p>

      <Button
        className="completed__btn"
        to="/auth/login"
        size="middle"
        type="link"
        color="orange"
      >
        Log In
      </Button>
    </div>
  );
};

export default UpdatedPassword;

import React, { useEffect, useState } from "react";

const PasswordRequirements = ({ password }) => {
  const [passwordValid, setPasswordValid] = useState({});

  const isValidValue = () => {
    if (password) {
      const hasMinLength = password.length >= 8;
      const hasNumbersAndLetters = /^(?=.*\d)(?=.*[a-zA-Z])/.test(password);
      const hasSpecialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(
        password
      );
      return { hasMinLength, hasNumbersAndLetters, hasSpecialCharacters };
    }

    return {};
  };

  useEffect(() => {
    setPasswordValid(isValidValue());
  }, [password]);

  const checkClass = (type) => {
    if (Object.keys(passwordValid).length) {
      if (passwordValid[type]) {
        return "success";
      } else {
        return "fail";
      }
    } else return "";
  };

  return (
    <ul className="auth-form__list-pattern list-pattern">
      <li className={`list-pattern__item ${checkClass("hasMinLength")}`}>
        <p className="list-pattern__text">
          The minimum password length is - 8 characters.
        </p>
      </li>

      <li
        className={`list-pattern__item ${checkClass("hasNumbersAndLetters")}`}
      >
        <p className="list-pattern__text">Contains both numbers and letters.</p>
      </li>

      <li
        className={`list-pattern__item ${checkClass("hasSpecialCharacters")}`}
      >
        <p className="list-pattern__text">Special characters are allowed.</p>
      </li>
    </ul>
  );
};

export default PasswordRequirements;

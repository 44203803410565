import { USER, ALERT } from "../../constants";
import axios from "@helpers/axios/private.axios";

export const updateUser = (data) => async (dispatch) => {
  console.log(data);
  try {
    const resp = await axios.post("/users/update", {
      ...data,
    });

    const { user } = resp.data.data;

    dispatch({
      type: USER.USER_RESPONSE,
      payload: { user },
    });
    dispatch({
      type: ALERT.ALERT_SUCCESS,
      payload: "Your data has been updated successfully",
    });
  } catch (e) {
    console.log(e);
    dispatch({ type: USER.USER_ERROR });
  }
};

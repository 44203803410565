import React, {useState} from 'react';
import {ClickAwayListener, Tooltip} from "@mui/material";
import {HelpIcon} from "@library";

export const MyTooltip = ({title, placement}) => {
  const [open, setOpen] = useState(false);

  
  const handleTooltipClose = () => {
    setOpen(false);
  }
  
  const handleTooltipOpen = () => {
    setOpen(true);
  }
  
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        title={title}
        placement={placement}
        className="tooltip__icon"
        slotProps={{
          tooltip: {
            className: "tooltip__popper",
          },
        }}
        open={open}
        disableFocusListener
        disableHoverListener
        onClick={handleTooltipOpen}
      >
          <span className="balance-card__help">
            <HelpIcon />
          </span>
      </Tooltip>
    </ClickAwayListener>
  );
};

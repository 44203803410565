import React, { useEffect, useState } from "react";
import { Box, Grid, Tooltip } from "@mui/material";
import { HelpIcon } from "@library";
import { Line } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS, Legend } from "chart.js/auto";
import { StrokeHelpIcon } from "../../library";
import axios from "@helpers/axios/private.axios";
import "./index.sass";

export const Dashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  ChartJS.register(ArcElement, Legend);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    // responsive: true,
    maintainAspectRatio: false,
    updateMode: "resize",
  };

  useEffect(() => {
    const getDashboard = async () => {
      try {
        setLoading(true);
        const resp = await axios.get("/dashboard");

        console.log("resp", resp.data.data);

        setData(resp.data.data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    getDashboard();
  }, []);

  return (
    <div className="main-info dashboard">
      <h1 className="main-info__title">Dashboard</h1>

      {!loading && (
        <>
          <section className="dashboard-visitors">
            <p className="dashboard__text">Visitors & Registrations</p>

            <div className="dashboard-visitors__wrapper">
              <div className="dashboard-visitors__graphics-wrap">
                <div className="dashboard-visitors__line-wrap">
                  <Line
                    className="dashboard-visitors__line"
                    data={{
                      labels: data.graphVisitorsLabels,
                      datasets: [
                        {
                          borderColor: "#1CC400",
                          backgroundColor: "transparent",
                          pointStyle: "circle",
                          color: "#fff",
                          borderWidth: 2,
                          data: data.graphVisitorsCounts,
                        },
                      ],
                    }}
                    options={options}
                  />
                </div>
                <div className="dashboard-visitors__line-wrap">
                  <Line
                    className="dashboard-visitors__line"
                    data={{
                      labels: data.graphRegisteredLabels,
                      datasets: [
                        {
                          borderColor: "#FF961B",
                          backgroundColor: "transparent",
                          pointStyle: "circle",
                          color: "#fff",
                          borderWidth: 2,
                          data: data.graphRegisteredCounts,
                        },
                      ],
                    }}
                    options={options}
                  />
                </div>
              </div>
              <div className="dashboard-visitors__info-wrap">
                <div className="dashboard-card">
                  <div className="dashboard-card__top">
                    <h3 className="dashboard-card__title">Visitors</h3>
                    <p className="dashboard-card__text">
                      Total number of new visitors for the entire period
                    </p>
                  </div>
                  <div className="dashboard-card__bottom">
                    <span className="dashboard-card__value">
                      {data.visitorsTotal}
                    </span>
                    <p className="dashboard-card__text">Total visitors</p>
                  </div>
                </div>
                <div className="dashboard-card">
                  <div className="dashboard-card__top">
                    <h3 className="dashboard-card__title">Registrations</h3>
                    <p className="dashboard-card__text">
                      Total number of new registrations for the entire period
                    </p>
                  </div>
                  <div className="dashboard-card__bottom">
                    <div className="dashboard-card__value">
                      {data.registeredTotal}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <p className="dashboard__text">
            Summary Total Balance: <span>{data.balance}</span>
          </p>

          <section className="dashboard-info">
            <div className="dashboard-info__wrap">
              <div className="dashboard-info__graphic">
                <p className="dashboard-info__text">
                  Count of First-time deposits
                </p>
                <span className="dashboard-info__value">
                  {data.totalFirstDeposits}
                </span>

                <div className="dashboard-info__line">
                  <Line
                    data={{
                      labels: data.graphFirstDepositsLabels,
                      datasets: [
                        {
                          borderColor: "#0084FF",
                          backgroundColor: "transparent",
                          pointStyle: "circle",
                          color: "#fff",
                          borderWidth: 2,
                          data: data.graphFirstDepositsCounts,
                        },
                      ],
                    }}
                    options={options}
                  />
                </div>
              </div>
              <div className="dashboard-info__graphic">
                <p className="dashboard-info__text">Number of deposits</p>
                <span className="dashboard-info__value">
                  {data.totalDeposits}
                </span>

                <div className="dashboard-info__line">
                  <Line
                    data={{
                      labels: data.graphDepositsLabels,
                      datasets: [
                        {
                          borderColor: "#0084FF",
                          backgroundColor: "transparent",
                          pointStyle: "circle",
                          color: "#fff",
                          borderWidth: 2,
                          data: data.graphDepositsCounts,
                        },
                      ],
                    }}
                    options={options}
                  />
                </div>
              </div>
              <div className="dashboard-info__graphic">
                <p className="dashboard-info__text">The sum of all deposits</p>
                <span className="dashboard-info__value">
                  {data.sumDeposits}$
                </span>

                <div className="dashboard-info__line">
                  <Line
                    data={{
                      labels: data.graphDepositsLabels,
                      datasets: [
                        {
                          borderColor: "#0084FF",
                          backgroundColor: "transparent",
                          pointStyle: "circle",
                          color: "#fff",
                          borderWidth: 2,
                          data: data.graphSumDepositsCounts,
                        },
                      ],
                    }}
                    options={options}
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="dashboard-sum-info">
            <Grid container rowSpacing="24px" columnSpacing="15px">
              <Grid md={4} xs={6} item>
                <article className="balance-card">
                  <div className="balance-card__top">
                    <p className="balance-card__text">Commission</p>
                    <Tooltip
                      title="The amount of commission that is paid for all time"
                      placement="right-start"
                      className="tooltip__icon"
                      slotProps={{
                        tooltip: {
                          className: "tooltip__popper",
                        },
                      }}
                    >
                      <span className="balance-card__help">
                        <HelpIcon />
                      </span>
                    </Tooltip>
                  </div>
                  <span className="balance-card__val">${data.commission}</span>
                </article>
              </Grid>
              <Grid md={4} xs={6} item>
                <article className="balance-card">
                  <div className="balance-card__top">
                    <p className="balance-card__text">Hold</p>
                    <Tooltip
                      title="The amount of commission that is on hold"
                      placement="right-start"
                      className="tooltip__icon"
                      slotProps={{
                        tooltip: {
                          className: "tooltip__popper",
                        },
                      }}
                    >
                      <span className="balance-card__help">
                        <HelpIcon />
                      </span>
                    </Tooltip>
                  </div>
                  <span className="balance-card__val">${data.hold}</span>
                </article>
              </Grid>
              <Grid md={4} xs={6} item>
                <article className="balance-card">
                  <div className="balance-card__top">
                    <p className="balance-card__text">Bonus</p>
                    <Tooltip
                      title="The amount of commission that is on hold"
                      placement="right-start"
                      className="tooltip__icon"
                      slotProps={{
                        tooltip: {
                          className: "tooltip__popper",
                        },
                      }}
                    >
                      <span className="balance-card__help">
                        <HelpIcon />
                      </span>
                    </Tooltip>
                  </div>
                  <span className="balance-card__val">+$0.00</span>
                </article>
              </Grid>
            </Grid>
          </section>

          <section className="dashboard-full-info">
            <Box sx={{ overflowX: "auto" }}>
              <Box sx={{ minWidth: 1065 }} className="table table--mini">
                <div className="table__tr table__tr--head">
                  <Box
                    sx={{
                      flex: "1 0 72px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">Visitors</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 98px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">Registrations</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 68px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">%CTR</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 67px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">
                      FTD
                      <Tooltip
                        title="The number of people who signed up using the link + topped up their first deposit (number of clients)"
                        placement="bottom"
                        className="tooltip__icon"
                        slotProps={{
                          tooltip: {
                            className: "tooltip__popper",
                          },
                        }}
                      >
                        <i className="dashboard-full-info__help">
                          <StrokeHelpIcon />
                        </i>
                      </Tooltip>
                    </p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 78px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">
                      FTDS
                      <Tooltip
                        title="Total number of first deposits per day, amount in $"
                        placement="top"
                        className="tooltip__icon"
                        slotProps={{
                          tooltip: {
                            className: "tooltip__popper",
                          },
                        }}
                      >
                        <i className="dashboard-full-info__help">
                          <StrokeHelpIcon />
                        </i>
                      </Tooltip>
                    </p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 70px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">
                      DPST
                      <Tooltip
                        title="The total number of deposits per day or the number of deposits of a specific client when entering a specific ID"
                        placement="bottom"
                        className="tooltip__icon"
                        slotProps={{
                          tooltip: {
                            className: "tooltip__popper",
                          },
                        }}
                      >
                        <i className="dashboard-full-info__help">
                          <StrokeHelpIcon />
                        </i>
                      </Tooltip>
                    </p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 64px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">
                      DPSTS
                      <Tooltip
                        title="The total amount of deposits per day or the total amount of deposits of a specific client when entering ID"
                        placement="top"
                        className="tooltip__icon"
                        slotProps={{
                          tooltip: {
                            className: "tooltip__popper",
                          },
                        }}
                      >
                        <i className="dashboard-full-info__help">
                          <StrokeHelpIcon />
                        </i>
                      </Tooltip>
                    </p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 77px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">
                      Profit
                      <Tooltip
                        title="The total amount of profit / loss of all referrals, or a specific ID"
                        placement="bottom-start"
                        className="tooltip__icon"
                        slotProps={{
                          tooltip: {
                            className: "tooltip__popper",
                          },
                        }}
                        enterTouchDelay={0}
                      >
                        <i className="dashboard-full-info__help">
                          <StrokeHelpIcon />
                        </i>
                      </Tooltip>
                    </p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 75px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">
                      Net profit
                      <Tooltip
                        title="Net profit / loss - the same, only the percentage of platform earnings is deducted and pure numbers remain for the affiliate program client"
                        placement="top"
                        className="tooltip__icon"
                        slotProps={{
                          tooltip: {
                            className: "tooltip__popper",
                          },
                        }}
                        enterTouchDelay={0}
                      >
                        <i className="dashboard-full-info__help">
                          <StrokeHelpIcon />
                        </i>
                      </Tooltip>
                    </p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 85px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">
                      Revenue
                      <Tooltip
                        title="Turnover - total trading turnover of referrals or a specific ID"
                        placement="top"
                        className="tooltip__icon"
                        slotProps={{
                          tooltip: {
                            className: "tooltip__popper",
                          },
                        }}
                        enterTouchDelay={0}
                      >
                        <i className="dashboard-full-info__help">
                          <StrokeHelpIcon />
                        </i>
                      </Tooltip>
                    </p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 100px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">
                      Net revenue
                      <Tooltip
                        title="Net turnover - the same trading turnover, only with the percentage of platform profits deducted"
                        placement="top"
                        className="tooltip__icon"
                        slotProps={{
                          tooltip: {
                            className: "tooltip__popper",
                          },
                        }}
                        enterTouchDelay={0}
                      >
                        <i className="dashboard-full-info__help">
                          <StrokeHelpIcon />
                        </i>
                      </Tooltip>
                    </p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 80px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">
                      Commission
                      <Tooltip
                        title="Commission - the amount that the client of the affiliate program earned every week and it was credited to his account for withdrawing money (before this, the funds keep in hold)"
                        placement="bottom"
                        className="tooltip__icon"
                        slotProps={{
                          tooltip: {
                            className: "tooltip__popper",
                          },
                        }}
                        enterTouchDelay={0}
                      >
                        <i className="dashboard-full-info__help">
                          <StrokeHelpIcon />
                        </i>
                      </Tooltip>
                    </p>
                  </Box>
                </div>
                <div className="table__tr">
                  <Box
                    sx={{
                      flex: "1 0 72px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">23</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 98px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">5</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 68px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">56%</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 67px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">3</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 78px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">$3,567</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 70px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">15</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 64px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">$1,453</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 77px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">$100</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 75px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">$56</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 85px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">$56</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 100px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">$56</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 80px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">$56</p>
                  </Box>
                </div>
              </Box>
            </Box>
          </section>
        </>
      )}
    </div>
  );
};
